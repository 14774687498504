export const parseSelectFilterOptions = uriComponentValue => {
  const startsWithHasAll = uriComponentValue && uriComponentValue.indexOf('has_all:') === 0;
  const startsWithHasAny = uriComponentValue && uriComponentValue.indexOf('has_any:') === 0;

  if (startsWithHasAll) {
    return uriComponentValue.substring(8).split(',');
  } else if (startsWithHasAny) {
    return uriComponentValue.substring(8).split(',');
  } else {
    return uriComponentValue.split(',');
  }
};

export const constructQueryParamName = (key, scope) => {
  const prefixedKey = scope === 'meta' ? `meta_${key}` : `pub_${key}`;
  return prefixedKey.replace(/\s/g, '_');
};

export const getQueryParamNames = (listingFieldsConfig, defaultFiltersConfig) => {
  // Add debug logs to trace values

  const queryParamKeysOfDefaultFilters = defaultFiltersConfig.reduce((pickedKeys, config) => {
    const { key, schemaType, scope, nestedParams } = config;
    const newKeys =
      schemaType === 'category' && nestedParams
        ? nestedParams?.map(p => constructQueryParamName(p, scope))
        : [key];
    return [...pickedKeys, ...newKeys];
  }, []);

  const queryParamKeysOfListingFields = listingFieldsConfig.reduce((params, config) => {
    const param = constructQueryParamName(config.key, config.scope);
    return config.filterConfig?.indexForSearch ? [...params, param] : params;
  }, []);

  return [...queryParamKeysOfDefaultFilters, ...queryParamKeysOfListingFields];
};

export const isAnyFilterActive = (filterKeys, urlQueryParams, filterConfigs) => {
  const { listingFieldsConfig, defaultFiltersConfig } = filterConfigs;

  const queryParamKeys = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

  const getQueryParamKeysOfGivenFilters = (pickedKeys, key) => {
    const isFilterIncluded = filterKeys.includes(key);
    const addedQueryParamNamesMaybe = isFilterIncluded ? [key] : [];
    return [...pickedKeys, ...addedQueryParamNamesMaybe];
  };
  const queryParamKeysOfGivenFilters = queryParamKeys.reduce(getQueryParamKeysOfGivenFilters, []);

  const paramEntries = Object.entries(urlQueryParams);

  const activeKey = paramEntries.find(entry => {
    const [key, value] = entry;
    return queryParamKeysOfGivenFilters.includes(key) && value != null;
  });

  return !!activeKey;
};

export const pickInitialValuesForFieldSelectTree = (prefix, values) => {
  const pickValuesFn = (picked, entry) => {
    const [key, value] = entry;
    const prefixIndex = key.indexOf(prefix);
    const startsWithPrefix = prefixIndex > -1;
    return startsWithPrefix ? { ...picked, [key.slice(prefixIndex)]: value } : picked;
  };
  const prefixCollection = Object.entries(values).reduce(pickValuesFn, {});
  return prefixCollection;
};

export const convertCategoriesToSelectTreeOptions = categories => {
  const convertSubcategoryData = params => {
    const { id, name, subcategories } = params;
    const suboptionsMaybe = subcategories
      ? { suboptions: subcategories.map(cat => convertSubcategoryData(cat)) }
      : {};
    return { option: id, label: name, ...suboptionsMaybe };
  };

  const categoriesArray = Array.isArray(categories) ? categories : [];
  return categoriesArray.map(cat => convertSubcategoryData(cat));
};

// src/util/search.js

export const cleanSearchFromConflictingParams = (currentQueryParams, filterConfigs, sortConfig) => {

  const { listingFieldsConfig, defaultFiltersConfig, listingCategories } = filterConfigs;

  const queryParamKeys = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);
  const conflictingFilterActive = isAnyFilterActive(sortConfig.conflictingFilters, currentQueryParams, filterConfigs);

  // Implement logic to clean search parameters based on conflicting filters
  // For now, returning currentQueryParams for demonstration purposes
  return currentQueryParams;
};


export const isMainSearchTypeKeywords = config =>
  config.search?.mainSearch?.searchType === 'keywords';

export const isOriginInUse = config =>
  config.search?.mainSearch?.searchType === 'location' && config.maps?.search?.sortSearchByDistance;

export const isStockInUse = config => {
  const listingTypes = config.listing.listingTypes;
  const stockProcesses = ['default-purchase'];
  const hasStockProcessesInUse = !!listingTypes.find(conf =>
    stockProcesses.includes(conf.transactionType.process)
  );

  // Note: these are active processes!
  return hasStockProcessesInUse;
};
